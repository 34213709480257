<template>
	<div class="inbox-item h-auto" :class="{ active }">
		<div class="inbox-item-section w-100">
			<router-link
				:to="{
					name: 'app.schedules.show',
					params: { id: calendar.id },
				}"
				class="inbox-item-title"
			>
				<div class="calendar-icon">
					<font-awesome-icon
						fixed-width
						:icon="['fas', 'calendar']"
					/>
				</div>
				{{ calendar.name }}
			</router-link>
		</div>
	</div>
</template>
<script>
export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if the provider is the current active provider being displayed.
		 *
		 * @return {Boolean}
		 */
		active() {
			if (!this.$route.params.id) {
				return false
			}

			return this.calendar.id === parseInt(this.$route.params.id)
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'CalendarCard',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The provider to be displayed.
		 *
		 * @type {Object}
		 */
		calendar: {
			type: Object,
			required: true,
		},
	},
}
</script>
