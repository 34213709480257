<template>
	<div class="inbox-item h-auto" :data-event="event">
		<div class="inbox-item-section w-100">
			<div class="inbox-item-title w-100">
				<div class="provider" :style="{ color }">
					<font-awesome-icon fixed-width :icon="['fas', 'circle']" />
				</div>
				<span :data-tooltip="fullName">
					{{ displayName }}
				</span>
				<div class="ml-auto text-gray-500 text-sm">
					<font-awesome-icon fixed-width :icon="['fal', 'arrows']" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import colors, { defaultColor } from '@/config/colors'

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the provider's schedule color HEX code.
		 *
		 * @return {String}
		 */
		color() {
			const color = colors.find(
				color => color.name === this.provider.color
			)

			return color ? color.code : defaultColor.code
		},

		/**
		 * Get the calendar's default timeblock.
		 *
		 * @return {Object?}
		 */
		defaultTimeBlock() {
			return this.timeBlocks.find(timeBlock => timeBlock.is_default)
		},

		/**
		 * Get the provider's name to be rendered.
		 *
		 * @return {String}
		 */
		displayName() {
			if (!this.provider.full_name) {
				return 'Provider Removed'
			}

			let [first_name, ...last_name] = this.provider.full_name.split(' ')

			const index = first_name.search(/[a-zA-Z]/) || 0

			const firstInitial = first_name.charAt(index)

			last_name = last_name.join(' ')

			if (this.provider.type == 'external') {
				return `(Ext) ${last_name}, ${firstInitial}.`
			}

			return `${last_name}, ${firstInitial}.`
		},

		/**
		 * The stringified event data to parse.
		 *
		 * @return {String}
		 */
		event() {
			return JSON.stringify({
				duration: '02:00',
				create: false,
				id: this.provider.id,
				title: this.displayName,
				type: this.findEventTypeByName('provider'),
				stick: true,
			})
		},

		/**
		 * Get the provider's full name.
		 *
		 * @return {String}
		 */
		fullName() {
			if (this.provider.user) {
				const { first_name, last_name } = this.provider.user

				return `${first_name} ${last_name}`
			}

			if (this.provider.full_name) {
				return this.provider.full_name
			}

			return 'Provider Removed'
		},

		/**
		 * Get the current calendars timeblocks.
		 *
		 * @return {Array}
		 */
		timeBlocks() {
			return this.getTimeBlocks(this.$route.params.id)
		},

		...mapGetters({
			findEventTypeByName: 'eventTypes/findByName',
			getTimeBlocks: 'timeBlocks/getByCalendarId',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'CalendarProviderCard',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The provider to be displayed.
		 *
		 * @type {Object}
		 */
		provider: {
			type: Object,
			required: true,
		},
	},
}
</script>
