<template>
	<div class="flex">
		<div class="inbox inbox-narrow">
			<div class="inbox-header pb-0 sticky top-0 z-50">
				<div class="inbox-title">
					<span class="inbox-title-label">
						{{ partner ? partner.name : 'Scheduling' }}
					</span>
				</div>
			</div>
			<div class="inbox-group border-t-0">
				<div
					v-if="activeCalendar"
					class="inbox-group-title collapsable"
					@click.prevent="isCalendarOpen = !isCalendarOpen"
				>
					Calendars
					<font-awesome-icon
						v-if="!isCalendarOpen"
						fixed-width
						:icon="['fas', 'chevron-down']"
					/>
					<font-awesome-icon
						v-else
						fixed-width
						:icon="['fas', 'chevron-up']"
					/>
				</div>
				<div v-else class="inbox-group-title collapsable">
					Calendars
				</div>
				<div v-if="!isCalendarOpen">
					<div
						class="inbox-item h-auto"
						:class="{ active: !$route.params.id }"
					>
						<div class="inbox-item-section w-100">
							<router-link
								:to="{
									name: 'app.schedules',
								}"
								class="inbox-item-title"
							>
								<div class="calendar-icon">
									<font-awesome-icon
										fixed-width
										:icon="['far', 'clipboard-list']"
									/>
								</div>
								Overview
							</router-link>
						</div>
					</div>
					<calendar-card
						v-for="calendar in calendars"
						:key="`calendar-${calendar.id}`"
						:calendar="calendar"
					/>
				</div>
			</div>

			<div
				v-if="showProviders"
				id="draggable-container"
				ref="providers"
				class="inbox-group"
			>
				<div
					v-if="internalProviders.length > 0"
					class="inbox-group-title"
				>
					Providers
				</div>
				<calendar-provider-card
					v-for="provider in internalProviders"
					:key="`internal-${provider.id}`"
					:provider="provider"
					class="draggable-card"
				/>
				<div
					v-if="externalProviders.length > 0"
					class="inbox-group-title"
				>
					External Providers
				</div>
				<calendar-provider-card
					v-for="provider in externalProviders"
					:key="`external-${provider.id}`"
					:provider="provider"
					class="draggable-card"
				/>
				<div v-if="providerGroups.length > 0" class="inbox-group-title">
					Provider groups
				</div>
				<calendar-group-card
					v-for="group in providerGroups"
					:key="`group-${group.id}`"
					:group="group"
					class="draggable-card"
				/>
				<div v-if="linkedPartners.length > 0" class="inbox-group-title">
					Affiliate practices
				</div>
				<calendar-linked-partner-card
					v-for="partner in linkedPartners"
					:key="`linked-partner-${partner.id}`"
					:linked-partner="partner"
					class="draggable-card"
				/>
			</div>
		</div>
		<div class="calendar">
			<div v-if="!loading" id="calendar-topbar" class="calendar-actions">
				<div>
					<button
						class="btn btn-raised mr-auto"
						type="button"
						@click.prevent="onTodayClick"
					>
						<div class="btn-ripple"></div>
						<span class="btn-label">Today</span>
					</button>
				</div>
				<h2 class="calendar-title-sm">{{ calendarName }}</h2>
				<div>
					<button
						class="calendar-action calendar-print-btn"
						data-tooltip="Print"
						aria-label="Print"
						data-position="bottom"
						@click.prevent="onPrint"
					>
						<font-awesome-icon
							fixed-width
							:icon="['fal', 'print']"
							aria-hidden="true"
						/>
					</button>
					<button
						v-if="activeCalendar && !identifyingGaps"
						class="calendar-action calendar-gap-btn"
						:data-tooltip="
							'Gap Identification: ' + (showGaps ? 'On' : 'Off')
						"
						:aria-label="
							'Gap Identification: ' + (showGaps ? 'On' : 'Off')
						"
						data-position="bottom"
						@click.prevent="onGapClick"
					>
						<font-awesome-icon
							fixed-width
							:icon="['fal', 'calendar-check']"
							aria-hidden="true"
						/>
					</button>

					<button
						v-else-if="activeCalendar && identifyingGaps"
						disabled
						class="calendar-action calendar-gap-btn"
						data-tooltip="Identifying Calendar Gaps"
						aria-label="Identifying Calendar Gaps"
						data-position="bottom"
					>
						<font-awesome-icon
							fixed-width
							spin
							:icon="['fal', 'spinner']"
							aria-hidden="true"
						/>
					</button>

					<router-link
						v-if="hasCoverMyCallEnabled"
						:to="{
							name: 'app.schedules.requests',
						}"
						class="calendar-action"
						data-tooltip="Cover My Call Requests"
						aria-label="Cover My Call Requests"
						data-position="bottom"
					>
						<font-awesome-icon
							fixed-width
							:icon="['fal', 'people-arrows']"
							aria-hidden="true"
						/>
					</router-link>
					<router-link
						:to="{
							name: 'app.schedules.time-blocks',
						}"
						class="calendar-action"
						data-tooltip="Time Blocks"
						aria-label="Time Blocks"
						data-position="bottom"
					>
						<font-awesome-icon
							fixed-width
							:icon="['fal', 'cogs']"
							aria-hidden="true"
						/>
					</router-link>
				</div>
			</div>
			<router-view
				v-if="calendars.length > 0 && !loading"
				:calendars="calendars"
				:show-gaps="showGaps"
				:gaps="gaps"
				@gaps:toggle="onGapsToggle"
				@calendar:dates="onDateChange"
				@gaps:fetch="fetchGaps"
			/>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import { sortBy } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import CalendarCard from '@/components/CalendarCard.vue'
import CalendarGroupCard from '@/components/CalendarGroupCard'
import CalendarProviderCard from '@/components/CalendarProviderCard.vue'
import CalendarLinkedPartnerCard from '@/components/CalendarLinkedPartnerCard'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		CalendarCard,
		CalendarGroupCard,
		CalendarLinkedPartnerCard,
		CalendarProviderCard,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the current active calendar.
		 *
		 * @return {?Object}
		 */
		activeCalendar() {
			const id = this.$route.params.id

			return id ? this.findCalendar(id) : null
		},

		/**
		 * Get all the calendars for the active partner.
		 *
		 * @return {Array}
		 */
		calendars() {
			if (!this.partner) {
				return []
			}

			return sortBy(this.getCalendars(this.partner.id), 'name')
		},

		/**
		 * Get current calendar from store.
		 *
		 * @return {String}
		 */
		calendarName() {
			const calendar = this.findCalendar(parseInt(this.$route.params.id))

			return calendar?.name || ''
		},

		/**
		 * Get the partner's external providers sorted by last name.
		 *
		 * @return {Array}
		 */
		externalProviders() {
			if (!this.activeCalendar) {
				return []
			}

			return this.getExternalByPartner(this.activeCalendar.partner_id)
		},

		/**
		 * Get the partner's internal providers sorted by last name.
		 *
		 * @return {Array}
		 */
		internalProviders() {
			if (!this.activeCalendar) {
				return []
			}

			return this.getInternalProviders
		},

		/**
		 * Get the linked partners.
		 *
		 * @return {Array}
		 */
		linkedPartners() {
			if (!this.partner) {
				return []
			}

			return sortBy(this.getLinkedPartners(this.partner.id), 'name')
		},

		/**
		 * Get the provider groups.
		 *
		 * @return {Array}
		 */
		providerGroups() {
			if (!this.activeCalendar) {
				return []
			}

			return this.getProviderGroups(this.activeCalendar.partner_id) || []
		},

		/**
		 * Determine if the partner providers list should be displayed.
		 *
		 * @return {Boolean}
		 */
		showProviders() {
			return !!this.$route.params.id && this.hasOfficeManagerAccess
		},

		...mapGetters('auth', ['hasOfficeManagerAccess']),
		...mapGetters({
			findCalendar: 'calendars/find',
			getExternalByPartner: 'providers/getExternalByPartner',
			getInternalProviders: 'providers/internal',
		}),
		...mapGetters('calendars', {
			getCalendars: 'getByPartner',
		}),
		...mapGetters('partners', {
			getLinkedPartners: 'getLinkedPartners',
			getProviderGroups: 'getProviderGroups',
			hasCoverMyCallEnabled: 'hasCoverMyCallEnabled',
			partner: 'active',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Fetch the inital calendar data from the API.
		 *
		 * @return {void}
		 */
		async getData() {
			this.loading = true

			try {
				await Promise.all([
					this.fetchCalendars(),
					this.fetchEventTypes(),
					this.fetchTimeBlocks(),
				])
			} catch (e) {
				this.$alert.error('default.error')
			}

			this.loading = false
		},

		/**
		 * Fetch gaps for the current calendar.
		 *
		 * @return {void}
		 */
		async fetchGaps() {
			if (!this.activeCalendar) {
				return
			}

			try {
				const request = this.$api.calendars().gaps()

				if (this.starts && this.ends) {
					request
						.start(moment(this.starts).format('YYYY-MM-DD'))
						.end(moment(this.ends).format('YYYY-MM-DD'))
				}

				const response = await request.get(this.activeCalendar.id)

				this.gaps = response.get('data')

				this.$alert.response(response)
			} catch (e) {
				this.showGaps = false

				this.$alert.response(e)
			}
		},

		/**
		 * Handle the print dialog being closed.
		 *
		 * @return {void}
		 */
		onAfterPrint() {
			this.dayMaxEventRows = 4
		},

		/**
		 * Handle the gap click event and reders the gaps when found.
		 *
		 * @return {void}
		 */
		async onGapClick() {
			if (!this.activeCalendar) {
				return (this.showGaps = false)
			}

			this.showGaps = !this.showGaps

			this.gaps = []

			if (this.showGaps && !this.gaps.length) {
				this.identifyingGaps = true

				await this.fetchGaps()

				setTimeout(() => (this.identifyingGaps = false), 1000)
			}
		},

		/**
		 * Handle the calendar date change.
		 *
		 * @param {String} starts
		 * @param {String} ends
		 * @return {void}
		 */
		onDateChange({ starts, ends }) {
			this.ends = ends
			this.starts = starts
		},

		/**
		 * Handle the on gaps toggle event.
		 *
		 * @param {String} starts
		 * @param {String} ends
		 * @return {void}
		 */
		onGapsToggle() {
			this.showGaps = !this.showGaps

			this.gaps = []
		},

		/**
		 * Handle the on print click event.
		 *
		 */
		async onPrint() {
			this.dayMaxEventRows = null

			await this.$nextTick()

			window.print()
		},

		/**
		 * Handle the on today click event.
		 *
		 * @return {void}
		 */
		onTodayClick() {
			this.$app.event().emit('schedules:today')
		},

		...mapActions({
			fetchCalendars: 'calendars/get',
			fetchEventTypes: 'eventTypes/get',
			fetchTimeBlocks: 'timeBlocks/get',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Schedule',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the current route for active partner changes.
		 *
		 * @param {Object} to
		 * @param {Object} from
		 * @return {void}
		 */
		$route(to, from) {
			this.gaps = []
			this.showGaps = false
			this.ends = null
			this.starts = null
			this.isCalendarOpen = !!this.$route.params.id

			if (to.params.partner !== from.params.partner) {
				this.getData()

				to.name === 'app.schedules' ||
					this.$router.push({ name: 'app.schedules' })
			}
		},
	},

	/**
	 * The component's before destroy lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		window.removeEventListener('afterprint', this.onAfterPrint)
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	async created() {
		window.addEventListener('afterprint', this.onAfterPrint)

		await this.getData()
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			dayMaxEventRows: 4,
			ends: null,
			isCalendarOpen: false,
			identifyingGaps: false,
			loading: true,
			gaps: [],
			showGaps: false,
			starts: null,
		}
	},
}
</script>
