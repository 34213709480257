<template>
	<div class="inbox-item h-auto" :data-event="event">
		<div class="inbox-item-section w-100">
			<div class="inbox-item-title w-100">
				<div class="provider" :style="{ color }">
					<font-awesome-icon fixed-width :icon="['fas', 'circle']" />
				</div>
				{{ displayName }}
				<div class="ml-auto text-gray-500 text-sm">
					<font-awesome-icon fixed-width :icon="['fal', 'arrows']" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { defaultColor } from '@/config/colors'

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the provider group's default color HEX code.
		 *
		 * @return {String}
		 */
		color() {
			return defaultColor.code
		},

		/**
		 * Get the calendar's default timeblock.
		 *
		 * @return {Object?}
		 */
		defaultTimeBlock() {
			return this.timeBlocks.find(timeBlock => timeBlock.is_default)
		},

		/**
		 * Get the  group's name to be rendered.
		 *
		 * @return {String}
		 */
		displayName() {
			return this.group.name
		},

		/**
		 * The stringified event data to parse.
		 *
		 * @return {String}
		 */
		event() {
			return JSON.stringify({
				duration: '02:00',
				create: false,
				id: this.group.id,
				title: this.displayName,
				type: this.findEventTypeByName('provider-group'),
				stick: true,
			})
		},

		/**
		 * Get the current calendars timeblocks.
		 *
		 * @return {Array}
		 */
		timeBlocks() {
			return this.getTimeBlocks(this.$route.params.id)
		},

		...mapGetters({
			findEventTypeByName: 'eventTypes/findByName',
			getTimeBlocks: 'timeBlocks/getByCalendarId',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'CalendarGroupCard',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The group to be displayed.
		 *
		 * @type {Object}
		 */
		group: {
			type: Object,
			required: true,
		},
	},
}
</script>
